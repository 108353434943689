import { initAll } from '../../../node_modules/@id-sk/frontend/idsk/all';
import { nodeListForEach } from '../../../node_modules/@id-sk/frontend/govuk/common';
import { selectWithConditionalItems } from './conditionalSelect';
import {
  duplicate,
  handleDuplicateAutocomplete,
  handleDuplicateSelectWithConditionalItems
} from './duplicate';
import { autocomplete } from './autocomplete';
import { modal } from './modal';
import './polyfills';
import './imageImports';
import './searchAgent';

window._components = window._components || {};

window._components = {
  autocomplete,
  duplicate,
  handleDuplicateAutocomplete,
  handleDuplicateSelectWithConditionalItems,
  selectWithConditionalItems
};

initAll();

// Initialize select with conditional item
document.querySelectorAll('[data-conditional-select]').forEach((el) => {
  if (!el.hasAttribute('data-conditional-select-no-init')) {
    selectWithConditionalItems(el).init();
  }
});

// Initialize duplicate
document.querySelectorAll('[data-module="duplicate"]').forEach((el) => {
  if (!el.hasAttribute('data-duplicate-no-init')) {
    duplicate(el).init();
  }
});

// Initialize autocomplete
document.querySelectorAll('[data-autocomplete]').forEach((el) => {
  if (!el.hasAttribute('data-autocomplete-no-init')) {
    autocomplete(el).init();
  }
});

// Initialize modals
var $modals = document.querySelectorAll('[data-module="modal"]');
nodeListForEach($modals, function ($modal) {
  modal($modal).init();
});
