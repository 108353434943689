import {
  autocomplete,
  getInstance as getInstanceAutocomplete
} from './autocomplete';
import {
  getInstance as getInstanceSelectWithConditionalItems,
  selectWithConditionalItems
} from './conditionalSelect';

export function duplicate(
  module,
  { onItemChange = () => {}, shouldAutoRebindInstances = true } = {}
) {
  let items = [];

  //Add static item (item which will be copied) to array
  //Find add new element button and add click event listener to it
  function init() {
    let autocompleteItems = module.querySelectorAll('div[data-duplicate-item]');
    autocompleteItems.forEach((item) => {
      items.push(item);
    });

    const addElementButton = module.querySelector(
      '[data-duplicate-item="add"]'
    );

    addElementButton.addEventListener('click', handleAdd);
    handleFirstDeleteButton();
  }

  function resetFieldState(field) {
    const event = new Event('change');
    // Input
    if (field.type === 'text') {
      field.value = null;
      field.dispatchEvent(event);
    }

    // Autocomplete
    if (field.hasAttribute('data-autocomplete')) {
      const input = field.querySelector('input');
      input.value = null;
      input.dispatchEvent(event);
    }

    // Checkbox & Radio
    if (field.type === 'checkbox' || field.type === 'radio') {
      field.checked = false;
      field.dispatchEvent(event);
    }

    // Select
    if (field.type === 'select-one') {
      // this won't work if first option is disabled
      field.selectedIndex = 0;
      field.dispatchEvent(event);
    }
  }

  // attemtp to rebind custom logic of elements when we have enaugh information in to do this automatically
  function autoRebindInstances(items) {
    items.forEach((item) => {
      item
        .querySelectorAll('[data-conditional-select]')
        .forEach((autocompleteEl) => {
          handleDuplicateSelectWithConditionalItems(autocompleteEl);
        });

      item.querySelectorAll('[data-autocomplete]').forEach((autocompleteEl) => {
        handleDuplicateAutocomplete(autocompleteEl);
      });
    });
  }

  //Create new item - copy item at position 0, i.e. original item
  //Add copied item to array
  //Find all elements, which have ID
  //Depending on element type remove their value
  function handleAdd(e) {
    const newItem = items[0].cloneNode(true);
    items.push(newItem);

    const newItemElements = newItem.querySelectorAll(
      '[data-duplicate-item-id]'
    );

    //Insert to page, before add-new button
    const parent = e.currentTarget
      .closest('[data-module="duplicate"]')
      // why div hear?
      .querySelector('div[data-duplicate-wrapper]');

    parent.insertBefore(newItem, e.currentTarget);

    //Focus on first element in copied block
    let focusedElement = newItem.querySelector('input,radio,checkbox,select');
    focusedElement?.focus();

    //Show remove button and add event listener to it
    const removeElementButton = newItem.querySelector(
      '[data-duplicate-item="remove"]'
    );
    removeElementButton.classList.remove(
      'govuk-checkboxes__conditional--hidden'
    );

    removeElementButton.addEventListener('click', handleRemove);

    handleAttributes();
    handleFirstDeleteButton();

    if (shouldAutoRebindInstances) {
      autoRebindInstances(items);
    }

    // Callback after item change
    items.forEach((item) => onItemChange?.(item));

    // Reset field to default state
    newItemElements.forEach((field) => {
      resetFieldState(field);
    });
  }

  //Find remove button's parrent and remove it from DOM and filter it from array
  function handleRemove(e) {
    const target = e.target;

    // why is here div? Are we trying to skip some attribute?
    target.closest('div[data-duplicate-item]').remove();

    const removed = items[target.id];
    items = items.filter(function (item) {
      return item !== removed;
    });

    handleAttributes();
    handleFirstDeleteButton();

    if (shouldAutoRebindInstances) {
      autoRebindInstances(items);
    }

    // Callback after item change
    items.forEach((item) => onItemChange?.(item));
  }

  // how to query in the DOM by part of attribute name (data-duplicate-*)?
  function handleAttributes() {
    items.forEach((item, index) => {
      const itemsWithFor = item.querySelectorAll('[data-duplicate-item-for]');
      const itemsWithName = item.querySelectorAll('[data-duplicate-item-name]');
      const itemsWithId = item.querySelectorAll('[data-duplicate-item-id]');
      const itemsWithAriaControls = item.querySelectorAll(
        '[data-duplicate-item-aria-controls]'
      );

      itemsWithId.forEach((item) => {
        item.setAttribute(
          'id',
          item.getAttribute('data-duplicate-item-id')?.replace('%index%', index)
        );
      });

      itemsWithName.forEach((item) => {
        item.setAttribute(
          'name',
          item
            .getAttribute('data-duplicate-item-name')
            ?.replace('%index%', index)
        );
      });

      itemsWithFor.forEach((item) => {
        item.setAttribute(
          'for',
          item
            .getAttribute('data-duplicate-item-for')
            ?.replace('%index%', index)
        );
      });

      itemsWithAriaControls.forEach((item) => {
        item.setAttribute(
          'aria-controls',
          item
            .getAttribute('data-duplicate-item-aria-controls')
            ?.replace('%index%', index)
        );
      });
    });
  }

  //Handle show/hide delete buttons
  function handleFirstDeleteButton() {
    if (items.length > 1) {
      items.map((item) => {
        const button = item.querySelector('[data-duplicate-item="remove"]');
        button.addEventListener('click', handleRemove);
        button.classList.remove('govuk-checkboxes__conditional--hidden');
      });
    } else {
      items.map((item) => {
        item
          .querySelector('[data-duplicate-item="remove"]')
          .classList.add('govuk-checkboxes__conditional--hidden');
      });
    }
  }

  return { init };
}

export function handleDuplicateAutocomplete(el, config = {}) {
  // store current values so we can applie them durring initialisation
  const id = el.getAttribute('id');
  const name = el.getAttribute('name');
  const value = el.querySelector('input')?.value;

  const instance = getInstanceAutocomplete(el);

  if (instance) {
    instance.destroy();
  } else {
    // if there is no instance we don't need to destroy anything
    el.innerHTML = '';
  }

  autocomplete(el, {
    id,
    name,
    defaultValue: value,
    ...config
  }).init();
}

export function handleDuplicateSelectWithConditionalItems(el) {
  const instance = getInstanceSelectWithConditionalItems(el);

  // destroy existing instance
  if (instance) {
    instance.destroy();
  }

  selectWithConditionalItems(el).init();
}
