export function selectWithConditionalItems(el, config = {}) {
  function init() {
    // Check if select controls anything
    // Check if content exists, before setting attributes.
    const controls = el.getAttribute('aria-controls');

    if (!controls || !document.getElementById(controls)) {
      return;
    }

    //Check if default value of element is 'other' and if it controls anything, in order to show conditional content when page is loaded
    if (el.value === 'other' && el.getAttribute('aria-controls')) {
      setAttributes(el);
    }

    el.addEventListener('change', handleChange);
  }

  function destroy() {
    el.removeEventListener('change', handleChange);
  }

  function handleChange(e) {
    var target = e.target;

    // If a select with aria-controls, handle click
    var isSelected = target.value;
    var hasAriaControls = target.getAttribute('aria-controls');
    if (isSelected && hasAriaControls) {
      setAttributes(target);
    }
  }

  function setAttributes(el) {
    const isOtherSelected = el.value == 'other';
    el.setAttribute('aria-expanded', isOtherSelected);

    var content = document.getElementById(el.getAttribute('aria-controls'));
    if (content) {
      content.classList.toggle(
        'govuk-radios__conditional--hidden',
        !isOtherSelected
      );
    }
  }

  el._component_selectWithConditionalItems = { init, destroy, config };

  return { init, destroy, config };
}

export function getInstance(el) {
  return el?._component_selectWithConditionalItems ?? null;
}
