//Search agent switch between mobile / desktop view
const filtersButton = document.querySelector(
  '.idsk-search-results__filters__button'
);
const backToResultsButton = document.querySelector(
  '.idsk-search-results__button--back-to-results'
);
const showFoundResultsButton = document.querySelector(
  '.govuk-button.idsk-search-results__button-show-results'
);

const searchAgentMobile = document.querySelector('#searchAgentWidget');

filtersButton?.addEventListener('click', () => {
  searchAgentMobile.classList.remove('search-agent-widget-mobile--hide');
});

showFoundResultsButton?.addEventListener('click', () => {
  searchAgentMobile.classList.add('search-agent-widget-mobile--hide');
});

backToResultsButton?.addEventListener('click', () => {
  searchAgentMobile.classList.add('search-agent-widget-mobile--hide');
});
